import * as React from 'react'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'

import { CommonReduxState } from '@igs-web/common-components/domain/common-redux'
import { MyAccountDashboardProvider } from '@igs-web/common-components/domain/myaccount/dashboard-context'
import { UserSelectors } from '@igs-web/common-components/domain/user/user-redux'

import { ProtectedRoute } from 'molecules/routing/protected-route'

import { DashboardReload } from 'domain/dashboard/dashboard-reload'
import { Dashboard } from 'domain/dashboard/gas-dashboard'
import { PaymentExtensionPage } from 'domain/payment-extension/payment-extension-page'
import { PayBillPage } from 'domain/payment/pay-bill-page'
import { GasMyProfilePage } from 'domain/profile/gas-my-profile'
import * as Routes from 'domain/routes'

export const RegulatedMyAccountRouter = () => {
    const userProfile = useSelector((store: CommonReduxState) => UserSelectors.selectProfile(store))

    if (!userProfile) {
        return <></>
    }
    return (
        <MyAccountDashboardProvider profile={userProfile}>
            <Switch>
                <ProtectedRoute path={Routes.AccountInformation}>
                    <GasMyProfilePage />
                </ProtectedRoute>
                <ProtectedRoute path={Routes.PayBill}>
                    <PayBillPage />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.DashboardReload}>
                    <DashboardReload />
                </ProtectedRoute>
                <ProtectedRoute path={Routes.AccountSummary}>
                    <Dashboard />
                </ProtectedRoute>
                <ProtectedRoute exact={true} path={Routes.PaymentExtension}>
                    <PaymentExtensionPage />
                </ProtectedRoute>
                <ProtectedRoute>
                    <Dashboard />
                </ProtectedRoute>
            </Switch>
        </MyAccountDashboardProvider>
    )
}
