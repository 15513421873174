import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { GlobalStyle } from 'scana/styles/app-styles'

import { SupportedCompanies } from '@igs-web/common-components/domain/company/company'
import { CommonWrapper } from '@igs-web/common-components/domain/render/common-wrapper'

import { persistor, store } from 'domain/redux'

import App from './App'

const getCompanyFromDocument = (): string => {
    const company = document.documentElement.id.toLowerCase()
    const supportedCompany = Object.keys(SupportedCompanies).find(supported => supported.toLowerCase() === company)
    if (!supportedCompany) {
        throw Error(`Unsupported Company: '${company}'`)
    }
    return SupportedCompanies[supportedCompany]
}

const element = document.getElementById('root') as HTMLElement
const root = createRoot(element)

root.render(
    <React.StrictMode>
        <CommonWrapper companyName={getCompanyFromDocument()} store={store} persistor={persistor}>
            <BrowserRouter>
                <GlobalStyle />
                <App />
            </BrowserRouter>
        </CommonWrapper>
    </React.StrictMode>,
)
